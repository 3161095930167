<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">运营商统计</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="10">
            <el-col :span="7">
              <el-form-item label="查询条件:">
                <el-select
                  v-model="FormData.dateType"
                  placeholder="月份"
                  style="width: 100%"
                  @change="chengeType"
                >
                  <el-option :value="0" label="月份" />
                  <el-option :value="1" label="日期" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="时间:" class="time_pick">
                <el-date-picker
                  v-if="FormData.dateType == 0"
                  style="height: 40px; width: 100%"
                  v-model="FormData.month"
                  type="month"
                  @change="changeMonth"
                  placeholder="选择月份"
                >
                </el-date-picker>
                <el-date-picker
                  v-if="FormData.dateType == 1"
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  align="right"
                  range-separator=" - "
                  start-placeholder="请选择开始日期"
                  end-placeholder="请选择结束日期"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="7">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <el-col :span="1" style="margin-left:40px">
              <Data2Excel api='/report/operatorrevenue' :data="dataa" fileName="运营商统计"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="运营商" prop="username" />
          <el-table-column
            label="总收入(元)"
            prop="money"
          />
          <el-table-column
            label="总电费(元)"
            prop="current"
          />
          <el-table-column
            label="服务费(元)"
            prop="server_money"
          />
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
// import { onMounted } from "vue";
import { ref } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { operator_revenue, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";
import { useRouter } from "vue-router";
import Data2Excel from "@/component/Data2Excel.vue"
export default {
  name: "StationManage",
  components:{
      Data2Excel
  },
  setup() {
    const router = useRouter();
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      FormData: {
        orderTime: ["", ""],
        operator_id: sessionStorage.getItem("userID"),
        dateType: 0,
      },
      tableData: [],
      tableData1: [],

      //   弹框

      loading: false,

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      options: regionData,
      selectedOptions: "",
      // 省市县

      temps: [],
      isadmin: true,
      users: [],
      type_id: [
        {
          label: "电动车",
          value: "2",
        },
        {
          label: "电动汽车",
          value: "4",
        },
      ],
      typeid: "",
      dataa:{}
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const chengeType = () => {
      data.FormData.orderTime = ["", ""];
    };
    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      data.dataa = {
        station_number: router.currentRoute
          ? router.currentRoute.value.query.station_number
          : "",
        operator_id: sessionStorage.getItem("userID"),
        start_time: stDate,
        end_time: endDate,
        username: data.FormData.username,
        page: data.currentPage,
      };
      data.loading = true;
      operator_revenue(data.dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
            data.tableData.forEach((a) => {
              a.money = Number(a.money)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                a.current = Number(a.current)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                a.server_money = Number(a.server_money)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };

    const changeMonth = (value) => {
      if (value != null && value != undefined && value != "") {
        let date = new Date(value);
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let day = new Date(year, month, 0);
        data.FormData.orderTime[0] = year + "-" + month + "-01";
        data.FormData.orderTime[1] = year + "-" + month + "-" + day.getDate();
      } else {
        data.FormData.orderTime[0] = "";
        data.FormData.orderTime[1] = "";
      }
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    onMounted(() => {
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      ref,
      searchBtn,
      rounding,
      changeMonth,
      getUsername,
      getList,
      handleSizeChange,
      handleCurrentChange,
      chengeType,
    };
  },
};
</script>
  <style scoped>
.formDiv {
  margin-top: 10px;
  width: 1200px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 500px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.s_title {
  font-size: 14px;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.statistics > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}
.input-with-select {
  width: 10vw;
}
</style>
  